<!-- 业务架构-->
<template>
  <div class="framework">
    <div class="all_box">
      <div class="framework_title">
        <h3>邦芒人力</h3>
        <p>BANGMANG</p>
      </div>
      <div class="framework_fencha">
        <img src="@/assets/framework_fc.png" />
      </div>
      <div class="framework_list">
        <div class="framework_item" :class="one_class"  @click="clickItem(1)">
          <h3>GOC</h3>
          <p>运营中心</p>
        </div>
        <div class="framework_item" :class="two_class" @click="clickItem(2)">
          <h3>TEC</h3>
          <p>技术中心</p>
        </div>
        <div class="framework_item" :class="three_class" @click="clickItem(3)">
          <h3>HRC</h3>
          <p>人力资源中心</p>
        </div>
        <div class="framework_item" :class="four_class" @click="clickItem(4)">
          <h3>BMC</h3>
          <p>品宣中心</p>
        </div>
        <div class="framework_item" :class="five_class" @click="clickItem(5)">
          <h3>FMC</h3>
          <p>财务中心</p>
        </div>
        <div class="framework_item" :class="six_class" @click="clickItem(6)">
          <h3>IDC</h3>
          <p>产业发展中心</p>
        </div>
      </div>
      <div class="framework_text_box" ref="text_box">
        <div class="sanjiao" ref="sanjiao"></div>
        <p v-if="bmType == 1">
          负责统筹全国各分公司的培训、管理、协调发展。根据公司总体战略目标和经营方针，合理制定运营计划，整理分析运营的实时数据，推动公司及全国市场沿着正确的战略方向发展。
        </p>
        <p v-if="bmType == 2">
          负责提供公司全流程业务的技术支持，制定以产品开发为中心的战略重点，服务并推动公司的技术进步，增强公司的技术开发和创新能力。
        </p>
        <p v-if="bmType == 3">
          负责依据公司的发展战略和规划拟订公司人力资源战略和目标、组织改革和架构设计、健全公司招聘和配置使用管理制度系统，促进公司经营战略目标的实现。
        </p>
        <p v-if="bmType == 4">
          负责公司的整体品牌建设、活动策划、执行宣传及维护。通过新闻宣传管理、媒体关系管理、广告管理、日常舆情监控及危机公关管理，帮助公司树立良好的品牌形象，提升品牌知名度。
        </p>
        <p v-if="bmType == 5">
          负责根据公司的发展规划和目标，建立符合公司经营需求的财务管理和资本运营体系；健全公司财务管理的各项规章制度；编制、下达并实施公司财务预算；核算公司的生产经营、资金运行情况，配合辅佐公司的整体业务战略。
        </p>
        <p v-if="bmType == 6">
          负责公司各事业部的整体产业发展规划及策略、产业基础研究；拟订中长期的产业发展规划、投资管理、产业重组、改制等工作，促进产业可持续性发展。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      one_class:'gaoliang_on',
      two_class:'gaoliang_off',
      three_class:'gaoliang_off',
      four_class:'gaoliang_off',
      five_class:'gaoliang_off',
      six_class:'gaoliang_off',
      bmType:1,
    };
  },
  methods: {
    clickItem(type) {
      this.bmType = type 
      this.one_class = 'gaoliang_off'
      this.two_class = 'gaoliang_off'
      this.three_class = 'gaoliang_off'
      this.four_class = 'gaoliang_off'
      this.five_class = 'gaoliang_off'
      this.six_class = 'gaoliang_off'
      if (type == 1) {
        this.one_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '60px'
      }
      if (type == 2) {
        this.two_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '264px'
      }
      if (type == 3) {
        this.three_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '464px'
      }
      if (type == 4) {
        this.four_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '670px'
      }
      if (type == 5) {
        this.five_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '877px'
      }
      if (type == 6) {
        this.six_class = 'gaoliang_on'
        this.$refs.sanjiao.style.left = '1080px'
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sanjiao{
  position: absolute;
  top: -58px;
  left: 60px;
  width: 0px;
  height: 0px;
  border-top: 30px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 30px solid #fff;
  border-right: 30px solid transparent;
}
.framework_text_box {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  background: #fff;
  box-sizing: border-box;
  padding: 45px 50px 55px;
  margin-top: 60px;
  p {
    margin: 0;
    font-size: 16px;
    color: #676767;
    line-height: 28px;
  }
}
.framework_fencha {
  img {
    display: block;
    margin: 0 auto;
  }
}
.framework_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  .gaoliang_on {
    background: #fff !important;
    h3 {
      color: #d70035 !important;
    }
    p {
      color: #696969 !important;
    }
  }

  .gaoliang_off{
    background: #d70035;
    h3{
      color: #fff;
    }
    p{
      color: #fff;
    }
  }

  .framework_item {
    cursor: pointer;
    background: #d70035;
    width: 15%;
    padding: 40px 0 34px;
    h3 {
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      margin: 0;
      margin-bottom: 25px;
    }
    p {
      font-size: 18px;
      color: #fff;
      margin: 0;
    }
  }
}
.framework_title {
  position: relative;
  height: 120px;
  h3 {
    font-size: 36px;
    color: #d70035;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 2;
  }
  p {
    opacity: 0.1;
    font-weight: bold;
    z-index: 1;
    font-size: 110px;
    margin: 0;
    position: absolute;
    width: 100%;
    top: 0;
    background-image: linear-gradient(
      to top,
      #fff,
      #d70035
    ); /* 线性渐变背景，方向向上 */
    -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
    -webkit-text-fill-color: transparent; /* 文字填充颜色变透明 */
  }
}
</style>