<template>
  <div class="about">
    <titles></titles>
    <div class="banner">
      <img src="@/assets/banner.jpg" />
    </div>

    <div class="about_nav">
      <div class="all_box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="邦芒简介" name="first"></el-tab-pane>
          <el-tab-pane label="企业文化" name="second"></el-tab-pane>
          <el-tab-pane label="发展历程" name="third"></el-tab-pane>
          <el-tab-pane label="公司荣誉" name="fourth"></el-tab-pane>
          <el-tab-pane label="业务架构" name="fifth"></el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="about_body_box">
      <introduction v-if="activeName == 'first'"></introduction>
      <civilization v-if="activeName == 'second'"></civilization>
      <course v-if="activeName == 'third'"></course>
      <honor v-if="activeName == 'fourth'"></honor>
      <framework v-if="activeName == 'fifth'"></framework>
    </div>

    <foots :type="3" @navChange = "navChange"></foots>
  </div>
</template>
<script>

import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
// 发展历程
import course from '@/components/about/course'
// 公司简介
import introduction from '@/components/about/introduction.vue'
// 业务架构
import framework from '@/components/about/framework.vue'
//企业文化
import civilization from '@/components/about/civilization.vue'
//公司荣誉
import honor from '@/components/about/honor.vue'
import { first } from "lodash";

export default {
  name: "About",
  components: {
    titles,
    foots,
    introduction,
    framework,
    course,
    civilization,
    honor
  },
  data() {
    return {
      activeName: "first",
      type:'',
    };
  },
  mounted(){
    window.scrollTo(0,0);
    this.type = this.$route.query.type
    if(this.type){
      this.initTabs(this.type)
    }
  },
  methods: {
    navChange(e){
      this.initTabs(e)
    },

    initTabs(e){
      if(e == 1){
        this.activeName = "first"
      }
      if(e == 2){
        this.activeName = "second"
      }
      if(e == 3){
        this.activeName = "third"
      }
      if(e == 4){
        this.activeName = "fourth"
      }
      if(e == 5){
        this.activeName = "fifth"
      }
      window.scrollTo(0,0);
    }
  },
};
</script>







<style lang="scss">
.about_nav {
  background: #fff;
  border-bottom: 3px solid #e0e0e0;
  height: 75px;
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__nav{
    float: inherit;
  }
  .el-tabs__item{
    padding: 0 100px;
    font-size: 18px;
    color: #696969;
  }
  .el-tabs__header{
    margin: 0;
  }
  .el-tabs__active-bar{
    background-color: #d70035;
  }
  .el-tabs__item.is-active{
    color:#696969 ;
    font-weight: bold;
  }
  .el-tabs__item:hover{
    color: #696969;
  }
  .el-tabs{
    height: 75px;
    line-height: 75px;

  }
}
</style>




<style lang="scss" scoped>
.about_body_box{
  padding-top: 80px;
  background: #f2f2f2;
}

</style>