<!-- 企业文化 -->
<template>
  <div class="introduction">
    <div class="introduction_siwper">
      <div class="all_box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in dataList"
              :key="index"
            >
              <div class="swiper_slide_box">
                <div class="swiper_silde_left">
                  <div class="swiper_left_text">
                    <h3>BANGMANG</h3>
                    <p>{{ item.bTitle }}</p>
                  </div>
                  <div class="swiper_left_video">
                    <video :src="item.video" controls="controls"></video>
                  </div>
                </div>
                <div class="swiper_silde_right">
                  <div class="silde_right_top">
                    <div class="silde_right_henxian"></div>
                    <h3>{{ item.vTitle }}</h3>
                  </div>
                  <div class="silde_right_text" v-html="item.text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      dataList: [
        {
          bTitle: "重人才培养 崇创新多元",
          vTitle: "赢在最青春",
          video:
            "https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/fe1133d3243791580028658622/TCgjdzo0NbcA.mp4",
          text: "<p>邦芒高度重视人才的培养及发展，打造“青芒计划”管培生培养方案，每年从高校应届毕业生中甄选百余名“高潜质、高素质、高认同”的优秀人才，基于“从实践中成长，从挑战中蜕变”的理念，通过定制化培养模式，打造年轻化、多元化的未来领导者，孕育创新共享的企业文化。<p>",
        },
        {
          bTitle: "激发活力 开放协同",
          vTitle: "健康月乐在邦芒",
          video:
            "https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/3f171291243791580029114677/u565F8ri9AcA.mp4",
          text: "<p>邦芒推崇“健康生活，快乐工作”的员工关怀理念，每年定期组织开展“健康月”主题活动，号召全员参与创意型团体户外健身项目，激发组织和个人活力，促进团队深度交流和协同共享，帮助员工和企业共同成长。</p>",
        },
        {
          bTitle: "逐梦不止 兴邦传芒",
          vTitle: "邦芒追梦人",
          video:
            "https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/0089793e243791580028776749/pAK8LpP1u0oA.mp4",
          text: "<p>邦芒坚持以客户为导向，不断迭代完善产品、服务、技术及团队再造，积极发现和表彰集团内创新、实干的“邦芒追梦人”们。他们勇于担当，敢于突破，在岗位上恪尽职守，发光发热，追求以专业的工作态度和能力造就专业的服务品质。</p>",
        },
      ],
    };
  },
  mounted() {
    this.handler();
  },
  methods: {
    //大swiper
    handler() {
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true,

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        console.log(mySwiper);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.introduction {
  position: relative;
  .swiper-button-prev:after {
    content: "";
    width: 26px;
    height: 19px;
    background: url(../../assets/serve_left.png) center center no-repeat;
  }
  .swiper-button-next:after {
    content: "";
    width: 56px;
    height: 18px;
    background: url(../../assets/serve_right.png) center center no-repeat;
  }
  .swiper-button-prev {
    left: auto;
    right: 60px;
    width: 26px;
    top: 80%;
  }
  .swiper-button-next {
    left: auto;
    right: -40px;
    width: 56px;
    top: 80%;
  }
}

video {
  width: 100%;
}
.swiper_slide_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  padding: 30px;
}
.swiper_silde_left {
  width: 50%;
  position: relative;
  .swiper_left_text {
    height: 127px;
    h3 {
      opacity: 0.1;
      font-size: 100px;
      background-image: linear-gradient(to bottom, #ce0d0d, #fbf3f3);
      -webkit-background-clip: text;
      color: transparent;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    p {
      font-size: 32px;
      color: #333333;
      margin: 0;
      position: absolute;
      top: 40px;
      width: 100%;
    }
  }
}
.swiper_silde_right {
  width: 50%;
  .silde_right_top {
    .silde_right_henxian {
      margin-top: 18px;
      margin-bottom: 30px;
      float: right;
      width: 44px;
      height: 3px;
      background: #d70035;
      display: inline-block;
    }
    h3 {
      font-size: 18px;
      color: #b29360;
      clear: both;
      margin: 0;
      text-align: right;
    }
  }
  .silde_right_text {
    padding-left: 100px;
    margin-top: 130px;
    color: #656565;
    text-align: left;
    line-height: 36px;
    font-size: 16px;
    text-indent: 2em;
    p {
      margin: 0;
    }
  }
}
</style>